<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.51 276.16">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <g>
            <path
              class="cls-1"
              d="m88.19,172.8l-.52-2.4c-.38-1.78-9.15-43.84,12.3-72.9,21.45-29.06,64.23-33.08,66.04-33.24l2.45-.21.52,2.4c.38,1.78,9.15,43.84-12.3,72.9-21.45,29.06-64.23,33.09-66.04,33.24l-2.45.21Zm75.79-102.68c-9.36,1.33-42.43,7.54-59.52,30.69-17.09,23.15-13.28,56.58-11.78,65.92,9.36-1.33,42.43-7.53,59.52-30.69h0c17.09-23.15,13.28-56.58,11.78-65.92Z"
            />
            <path
              class="cls-1"
              d="m90.4,172.6c-.58,0-1.16-.18-1.65-.54-1.24-.91-1.5-2.66-.59-3.9l58.45-79.19c.91-1.24,2.66-1.5,3.9-.59,1.24.91,1.5,2.66.59,3.9l-58.45,79.19c-.55.74-1.39,1.13-2.25,1.13Z"
            />
            <path
              class="cls-1"
              d="m120.66,131.6c-1.37,0-2.57-1.02-2.76-2.42-.21-1.53.87-2.93,2.39-3.13l27.94-3.75c1.55-.22,2.93.87,3.13,2.39.21,1.53-.87,2.93-2.39,3.13l-27.94,3.75c-.13.02-.25.03-.38.03Z"
            />
            <path
              class="cls-1"
              d="m106.67,150.54c-1.34,0-2.52-.97-2.75-2.33l-4.65-27.8c-.25-1.52.77-2.96,2.29-3.21,1.51-.25,2.95.77,3.21,2.29l4.65,27.8c.25,1.52-.77,2.96-2.29,3.21-.15.03-.31.04-.46.04Z"
            />
            <path
              class="cls-1"
              d="m133.39,114.34c-1.34,0-2.52-.97-2.75-2.33l-3.43-20.49c-.25-1.52.77-2.96,2.29-3.21,1.5-.25,2.95.77,3.21,2.29l3.43,20.49c.25,1.52-.77,2.96-2.29,3.21-.15.03-.31.04-.46.04Z"
            />
          </g>
          <g>
            <path
              class="cls-1"
              d="m77.05,170.69l-2.45-.21c-1.48-.13-36.52-3.42-54.11-27.26-17.59-23.83-10.41-58.28-10.1-59.74l.52-2.4,2.45.21c1.48.13,36.52,3.42,54.11,27.26,17.59,23.83,10.41,58.28,10.1,59.74l-.52,2.4ZM15.41,87.17c-1.25,8.16-3.93,34.45,9.57,52.74h0c13.52,18.32,39.41,23.49,47.58,24.69,1.25-8.16,3.93-34.45-9.57-52.74-13.52-18.32-39.41-23.49-47.58-24.69Z"
            />
            <path
              class="cls-1"
              d="m74.84,170.49c-.86,0-1.7-.39-2.25-1.13L25.04,104.92c-.91-1.24-.65-2.98.59-3.9,1.24-.91,2.98-.65,3.9.59l47.56,64.44c.91,1.24.65,2.98-.59,3.9-.5.37-1.08.54-1.65.54Z"
            />
            <path
              class="cls-1"
              d="m50.22,137.13c-.12,0-.25,0-.38-.03l-22.73-3.05c-1.52-.21-2.6-1.61-2.39-3.13.2-1.52,1.59-2.62,3.13-2.39l22.73,3.05c1.52.21,2.6,1.61,2.39,3.13-.19,1.4-1.38,2.42-2.76,2.42Z"
            />
            <path
              class="cls-1"
              d="m61.6,152.54c-.15,0-.31-.01-.46-.04-1.52-.25-2.54-1.69-2.29-3.21l3.78-22.62c.25-1.52,1.68-2.54,3.21-2.29,1.52.25,2.54,1.69,2.29,3.21l-3.78,22.62c-.23,1.36-1.41,2.33-2.75,2.33Z"
            />
            <path
              class="cls-1"
              d="m39.86,123.09c-.15,0-.31-.01-.46-.04-1.52-.25-2.54-1.69-2.29-3.21l2.79-16.67c.25-1.52,1.69-2.54,3.21-2.29,1.52.25,2.54,1.69,2.29,3.21l-2.79,16.67c-.23,1.36-1.41,2.33-2.75,2.33Z"
            />
          </g>
          <path
            class="cls-1"
            d="m83.57,275.62c-1.54,0-2.79-1.25-2.79-2.79v-94.48c0-1.54,1.25-2.79,2.79-2.79s2.79,1.25,2.79,2.79v94.48c0,1.54-1.25,2.79-2.79,2.79Z"
          />
        </g>
        <path
          class="cls-1"
          d="m121.02,62.62c-.95,0-1.89-.49-2.41-1.38-10.79-18.37-22.58-35.71-35.06-51.59-11.48,14.65-22.41,30.63-33.32,48.74-.79,1.32-2.51,1.74-3.83.95-1.32-.8-1.74-2.51-.95-3.83,11.73-19.46,23.48-36.5,35.91-52.09l2.18-2.73,2.18,2.73c13.46,16.83,26.14,35.34,37.7,55,.78,1.33.34,3.04-.99,3.82-.44.26-.93.38-1.41.38Z"
        />
        <path
          class="cls-1"
          d="m82.79,275.72c-16,0-32.07-4.4-45.94-13.62-16.06-10.68-27.4-27.42-32.8-48.41-4.59-17.86-4.82-38.79-.64-58.92.31-1.51,1.8-2.48,3.3-2.16,1.51.31,2.48,1.79,2.16,3.3-10.89,52.41,8.91,86.83,31.06,101.56,34.51,22.94,83.6,13.95,107.19-19.64,17.66-25.14,15.94-57.52,11.38-80.26-.3-1.51.68-2.98,2.19-3.28,1.51-.29,2.98.68,3.28,2.19,4.77,23.81,6.51,57.81-12.29,84.56-15.8,22.49-42.26,34.7-68.9,34.7Z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #0d758f;
  stroke: #0d758f;
  stroke-miterlimit: 10;
  stroke-width: 0.87px;
}
</style>
